import React from "react"
import {Link} from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import './app.css'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="fourOfourContainer">
      <h1>Sorry, but</h1>
      <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
      <p>Click below to go somewhere relevant</p>
      <Link to="/"> Go Home</Link>
    </div>
  </Layout>
)

export default NotFoundPage
