import React from "react"

import Layout from "../components/layout"
import {Helmet} from 'react-helmet'
import SEO from "../components/seo"
import LandingPage from './landing-page'

const IndexPage = () => (
  <Layout>
    <Helmet>
      <meta name='viewport' content='initial-scale=1, viewport-fit=cover' />
    </Helmet>
    <SEO title="Home" />
    <LandingPage />
  </Layout>
)

export default IndexPage
