import React, { Component } from 'react';
import logo from "../images/1277Logo.svg"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Layout from '../components/layout';


class LandingPage extends Component {
    constructor(context, props) {
        super(props);
        this.state = {
            showAbout: false,
            muted: true,
            volume: "0.6",
        }
        this.muteAudio = this.muteAudio.bind(this);

    };

    muteAudio() {
        this.setState(prevState => ({ muted: !prevState.muted }))
    }

    render() {
        return (
            <div className="mainContainer">
                <div className={"logoContainer"} >

                    <section className={"spinningCircle"} onClick={this.showAbout}></section>
                    <AniLink cover direction="up" to ="/about" 
                        bg = "#121212"
                        duration = {2}
                        style={{display: 'flex', justifyContent: 'center'}}>
                         <img src={logo} alt="logo" className={"logo"} />
                    </AniLink>
                </div>

                {/* <SocialBar /> */}

                <div className="perspective">
                    <p>Maybe try a change of perspective?</p>
                </div>
            </div>
        );
    }

}

export default LandingPage