import React, {Component} from "react"
import {Helmet} from 'react-helmet'
import SEO from "../components/seo"
import Layout from '../components/layout'
import logo from '../images/1277Logo.svg'
import backgroundVideo from '../images/loop.mp4'
import AniLink from "gatsby-plugin-transition-link/AniLink"

class About extends Component {
  constructor(context,props) {
    super(props);

  };

  render() {
    return(
      <Layout>
        <SEO title="about" />
        <div class="about-container">
            <AniLink cover direction="up" to="/"
              bg = "#121212"
              duration = {2}>
             <img src={logo} alt="logo" className= "logo-about" />
            </AniLink>
           
              
            <section class="about">
              <div>
                  <p style={{ color: `white`, opacity: 0.87 }}>
                      12°77° is a design company based in Chicago that is focused 
                      on creating a high grade, socially conscious wear and experience. 
                      The centralized focus point for the brand is the vaporous inherent 
                      attributes of Life and Fashion expressed through their stylistic 
                      distinctions.
                  </p>

                  <p style={{ color: `white`, opacity: 0.87 }}>
                    12°77° aims to generate meaningful wear that embodies a familial 
                    and cultural perspective from Peruvian heritage. Every piece is meticulously 
                    designed and formed to create an unparalleled experience with the end of expressing.
                  </p>

              </div>
          </section>

          <video playsInline autoPlay muted loop id={ "backgroundVideo" }>
              <source src={backgroundVideo} type="video/mp4" />
          </video>

          <div className="aboutOverlay" />  
        </div>

        <div className="perspective">
          <p>Maybe try a change of perspective?</p>
        </div>

      </Layout>
    )}
}

export default About
